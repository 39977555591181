import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation, MobileNavigation } from '.'
import config from '../../utils/siteConfig'
import LayoutStyles from  '../../styles/layout.module.css';
import CookieConsent, { Cookies } from "react-cookie-consent";

// Styles
import  '../../styles/app.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    
    const tags = data.allGhostTag.edges.map(function(item) {
        return {name: item.node.name, slug: item.node.slug}
    })
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className={LayoutStyles.viewport}>
                
                    <div className="viewport-top">
                        
                            <div className={LayoutStyles.headerTop}>
                                <div className={LayoutStyles.container}>
                                    <div className={LayoutStyles.headerRight}>
                                        <div className={LayoutStyles.socialIcons}>
                                        { site.facebook && <a href={ facebookUrl } className={LayoutStyles.socialIcon} target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook-black.svg" alt="Facebook" /></a>}
                                        { site.facebook && <a href="https://www.instagram.com/curlybulgaria/" className={LayoutStyles.socialIcon} target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/instagram.svg" alt="Instagram" /></a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                       
                        <div className={LayoutStyles.header}>
                            <div className={LayoutStyles.siteLogo}>
                                <Link to="/">
                                    {site.logo ?
                                        <img className="site-logo" src={site.logo} alt={site.title} />
                                        : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                    }
                                </Link>
                            </div>
                            <div className={LayoutStyles.mobileNavigation}>
                                    <MobileNavigation data={site.navigation} navClass="site-nav-item" tags={data.allGhostTag.edges.map(function(item) {
                return {name: item.node.name, slug: item.node.slug}
            })}/>
                            </div>
                            {/* The main header section on top of the screen */}
                            <div className={LayoutStyles.navigation}><Navigation data={site.navigation} navClass="site-nav-item" tags={data.allGhostTag.edges.map(function(item) {
        return {name: item.node.name, slug: item.node.slug}
    })}/></div>
                            
                        </div>  
                        <main className="site-main">
                                {/* All the main content gets inserted here, index.js, post.js */}
                                {children}
                            </main>
                    </div>
               

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                {/* <Link to="/">{site.title}</Link> © 2021 &mdash; Published with <a className="site-foot-nav-item" href="https://ghost.org" target="_blank" rel="noopener noreferrer">Ghost</a> */}
                            </div>
                            <div className="site-foot-nav-right">
                                <Navigation data={site.navigation} navClass="site-foot-nav-item" />
                            </div>
                        </div>
                    </footer>

                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="Запази настройките"
                cookieName="Curlybgcookie"
                style={{ background: "#725498" }}
                buttonStyle={{ color: "#FFF", fontSize: "13px", background: "#98c93c" }}
                expires={150}
                >
                При натискане на "Запази настройките", или ако продължите да разглеждате сайта, Вие приемате всички бисквитки, чиято цел е подобряване на навигацията, анализа на потреблението и участие в провеждането на различни маркетингови кампании.{" "}
                {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
        
    }).isRequired,
    
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                allGhostTag {
                    edges {
                      node {
                        slug
                        name
                      }
                    }
                  }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
