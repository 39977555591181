import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {v4 as uuidv4} from 'uuid';

import LayoutStyles from  '../../styles/layout.module.css';

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const Navigation = ({ data, navClass, tags}) => (
    
    <ul>
        
        <li><Link className={LayoutStyles.navItem} to="/" key={uuidv4()} >Начало</Link></li>
        {data.map((navItem, i) => {
            if (navItem.url.match(/^\s?http(s?)/gi)) {
                return <li key={uuidv4()} ><a className={LayoutStyles.navItem} href={navItem.url} target="_blank" rel="noopener noreferrer">{navItem.label}</a></li>
            } else {
                return <li key={uuidv4()}>
                        <Link className={LayoutStyles.navItem} to={navItem.url} >{navItem.label}</Link>
                       
                    </li>
            }
        })}
        <li>
            <Link className={LayoutStyles.navItem} to="/blog"  >Блог</Link>
            <div className={LayoutStyles.megamenu}>
                {tags && tags.map((tag) => {
                   return <div className={LayoutStyles.submenuItem} key={uuidv4()}>
                        <a href={`/tag/${tag.slug}`} >{tag.name}</a>
                    </div>
                })}
               
            </div>
        </li>
    </ul>
)

Navigation.defaultProps = {
    navClass: `site-nav-item`,
}

Navigation.propTypes = {
   
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    navClass: PropTypes.string,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
        })
    )
}

export default Navigation
