import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'

const PostCard = ({ post, isLarge }) => {
    const url = `/blog/${post.slug}/`
    const readingTime = readingTimeHelper(post)
    const articleClasses = isLarge ? "post-card post-card-large" : "post-card"
   
    return (
        <article className={articleClasses}>
            <Link to={url} className="post-card-image-link">
                
                    {post.feature_image &&
                        <img className="post-card-image" src={post.feature_image}></img>}
                    {/* {post.tags && <div className="post-card-tags"> <Tags post={post} visibility="public" autolink={false} /></div>}
                    {post.featured && <span>Featured</span>} */}
                    {/* <h2 className="post-card-title">{post.title}</h2> */}
                
                
            </Link>
            <div className="post-card-content">
                    <Link className="post-card-content-link" to={url}>
                        <div className="post-card-primary-tag">{post.tags[0] && post.tags[0].name}</div>
                        <header>
                            <h2 className="post-card-title">{post.title}</h2>   
                        </header>
                        <section className="post-card-excerpt">
                            <p>{post.excerpt}</p>
                        </section> 
                        <div className="button-contaner"><div className="post-card-button">Виж още</div></div>
                    </Link>
                   
                </div>
        </article>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    isLarge: PropTypes.bool
}

export default PostCard
