import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {v4 as uuidv4} from 'uuid';

import LayoutStyles from  '../../styles/layout.module.css';
import MobileStyles from  '../../styles/mobileNavigation.module.scss';
/**
* MobileNavigation component
*
* The MobileNavigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const MobileNavigation = ({ data, navClass, tags}) => (
    <>
   
    <nav role="custom-dropdown">
        <input type="checkbox" id="menu"></input>
        <label htmlFor="menu"></label>
        <ul>
            <li key={uuidv4()}><Link to="/">Начало</Link></li>
            {data.map((navItem, i) => {
            if (navItem.url.match(/^\s?http(s?)/gi)) {
                return <li key={uuidv4()}><a href={navItem.url} target="_blank" rel="noopener noreferrer">{navItem.label}</a></li>
            } else {
                return <li key={uuidv4()}>
                        <Link to={navItem.url} >{navItem.label}</Link>
                    </li>
            }
        })}
            <li key={uuidv4()}><Link to="/blog">Блог</Link></li>
            {tags && tags.map((tag) => {
                   return <li className={MobileStyles.blogTag} key={uuidv4()} >
                        <a href={`/tag/${tag.slug}`}>{tag.name}</a>
                    </li>})}
        </ul>
    </nav>
    </>
)

MobileNavigation.defaultProps = {
    navClass: `site-nav-item`,
}

MobileNavigation.propTypes = {
   
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    navClass: PropTypes.string,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
        })
    )
}

export default MobileNavigation
